/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// console.log('Hello World from Webpacker')

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('@rails/actioncable').createConsumer();

import 'bootstrap/dist/js/bootstrap'
import '../src/application'

import 'litepicker/dist/js/main'
import 'select2/dist/js/select2.min'

// jQuery Ui
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/position'
import 'jquery-ui/ui/keycode'
import 'jquery-ui/ui/unique-id'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ui/ui/widgets/menu'

import 'jquery-slimscroll/jquery.slimscroll'
import 'fullcalendar/dist/fullcalendar'
